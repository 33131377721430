.weather-widget-fsd {
  font-family: 'Arial', sans-serif;
  /* background: rgba(106, 118, 101, 0.7); Dark background with opacity */
  backdrop-filter: blur(5px); /* Blur effect */
  border-radius: 20px;
  overflow: hidden;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.356); */
  /* max-width: 90%; */
  /* width: 100%; */
  margin: 20px auto;
  color: white;
  box-sizing: border-box;
  padding: 10px;
}

.current-weather {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.weather-widget-logo {
  text-align: end;
  width: 100%;
  margin-top: 10px;
}

.current-weather h2 {
  margin: 0;
  font-size: 2.5em;
  color: white;
  width: 100%;
  text-align: center;
}

.current-weather-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.current-weather-details span {
  font-size: 1.2em;
}

.forecast {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 10px;
}

.forecast-day {
  text-align: center;
  flex-basis: 30%;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.2); /* Slight background color for separation */
  border-radius: 10px;
  padding: 10px;
  transition: transform 0.2s ease, background 0.2s ease; /* Transition for hover effect */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.forecast-day:hover {
  background: rgba(255, 255, 255, 0.3); /* Brighter background on hover */
  transform: translateY(-5px); /* Slight lift on hover */
}

.forecast-day span {
  display: block;
  font-size: 1.2em;
  margin-bottom: 5px;
  color: white;
}

.forecast-day img {
  width: 40px;
  height: 40px;
}

.temp-range {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.temp-range span {
  font-weight: bold;
  font-size: 1.2em;
}


.suggestions-list {
  margin-top: 5px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #fff;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.suggestion-item:hover {
  background-color: #444;
}

.search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.search-input {
  width: 100%;
  /* max-width: 250px;  */
  padding: 10px;
  border: 2px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #fff;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input::placeholder {
  color: #aaa;
  font-style: italic;
}

.search-input:focus {
  border-color: #1e90ff;
}

.search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 12px;
  border: none;
  border-radius: 5px;
  background-color: #1e90ff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #007acc;
}

@media (max-width: 600px) {
  .search-form {
    flex-direction: column;
    align-items: stretch;
    position: static; /* Reset position for mobile */
  }
.go-back-button{
  margin-top: 0px;
}
  .search-input {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
    padding-right: 45px; /* Space for button */
  }

  .search-button {
    width: 100%;
    height: auto;
    padding: 8px;
    font-size: 14px;
    position: static; /* Reset position for mobile */
  }
}

@media (max-width: 400px) {
  .search-button {
    padding: 6px;
    font-size: 12px;
  }
}




@media (max-width: 768px) {
  .current-weather h2 {
    font-size: 2em;
  }

  .forecast-day {
    flex-basis: 45%;
  }

  .forecast-day img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .current-weather h2 {
    font-size: 1.5em;
  }

  .forecast-day {
    flex-basis: 100%;
  }

  .forecast-day img {
    width: 25px;
    height: 25px;
  }

  .temp-range span {
    font-size: 1em;
  }
}

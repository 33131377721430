.grand-father {
  direction: rtl;
  font-family: "Noto Nastaliq Urdu", serif;
  /* height: 29.7cm;  */
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden;
  position: relative; /* Positioning context for background image */
}

.background-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1; /* Low opacity */
  width: 10%; 
  height: 100%;
  z-index: 0; /* Ensure it is behind other content */
}

.content-wrapper {
  position: relative; /* To ensure the content is above the background image */
  z-index: 1;
}

.main-heading-p1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  text-align: center;
  
}



.main-heading-p1 img {
  max-height: 50px;
}

.heading-r1,
.heading-r2 {
  font-size: 30px;
}
.heading-r4{
    font-size: 23px;

}
.subtittle-r4r1{
    /* font-size: 16px; */
    /* text-align: justify; */

}

.subtittle-r2,
.subtittle-r3 {
  font-size: 22px;
  text-align: justify;
  line-height: 2.3;
}

.subtittle-r2r2{
  font-size: 18px;
  line-height: 2.3;

}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.print-button-container {
  text-align: center;
}

.print-button-container .btn {
  padding: 10px 20px;
  font-size: 16px;
}




@media print {
  .hide-on-print {
    display: none;
  }
  .new-page-content {
    page-break-before: always;
  }
  .print-page-width{
    width: 100%;
  }
  /* .d-print-block{
    display: flex;
    justify-content: space-between;
  } */
   .ndmi-width-print{
    width: 300px;
    height: 300px;
   }
  
}


/* In your Signup.css file */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #9fb4c7 !important; /* Ensure the text color is consistent */
  background-color: #1c1f2a !important; /* Set your desired background color */
}

.main-container {
  width: 100%;
  overflow: hidden;
  background-color: #181f26;
}

.main-login {
  display: flex;
  /* height: 100vh; */
  align-items: center;
  justify-content: center;
}

.line-with-text {
  position: relative;
  width: 100%;
  padding: 0 0 22px;
  margin-bottom: 16px;
  border-bottom: 1px solid #323d49;
}

.or-text {
  display: block;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translate(-50%);
  padding: 3px;
  background: #212930;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  color: #fff;
  text-transform: uppercase;
}

/* .bg-img {
  height: 100vh;
} */

.signUp-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #212930;
  box-shadow: 0 14px 12px 0 rgba(0, 0, 0, 0.2),
    0 10px 25px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
  border: 1px solid #323d49;
}

.signUp-new-Container {
  padding: 10px;
}

@media (max-width: 992px) {
  .main-container {
    position: relative;
  }

  .bg-img {
    display: none !important;
  }

  .signUp-section {
    width: 100%;
    height: 100vh;
    background-image: url("../Assets/Signup-Images/wheat-mobile-login.jpg");
    border-radius: 0px;
    background-size: cover;
    background-position: center;
  }
  .signUp-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 1;
  }

  .signUp-new-Container {
    background: rgba(38, 38, 38, 0.8); /* Semi-transparent background */
    padding: 20px;
    border-radius: 25px;
    z-index: 2; /* Ensure content is above overlay */

    backdrop-filter: blur(5px); /* Blur effect */
    width: 90%;
    /* height: 98%; */
  }
}

.all-signup-error {
  color: red;
  height: 1.2em; /* Set minimal height */
  overflow: hidden;
  position: absolute;
  font-size: 15px;
}
/* .signup-error {
  margin-bottom: 15px;
} */
/* 
.rtl-placeholder::placeholder {
  text-align: right;
  direction: rtl;
}

.ltr-placeholder::placeholder {
  text-align: left;
  direction: ltr;
} */


@media (max-width: 992px) {
}

@media (max-width: 992px) {
  .language-switcher-login {
  }
}

.not-found-container {
    text-align: center;
    padding: 5vw; /* Make padding responsive */
    min-height: 100vh; /* Ensure the container takes full height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .vector-image {
    margin-bottom: 30px;
    max-width: 80vw; /* Adjust image width responsively */
  }
  
  .vector-image img {
    width: 100%; /* Make the image responsive */
    height: auto;
    max-width: 500px; /* Limit the max size of the image */
  }
  
  .not-found-container h2 {
    font-size: 2vw; /* Scale text size with the viewport */
    margin: 20px 0;
  }
  
  .not-found-container p {
    font-size: 1.5vw; /* Scale text size with the viewport */
    margin-bottom: 30px;
    color: #555;
  }
  
  .back-home-btn {
    background-color: #007bff;
    color: white;
    font-weight: 600;
    border: none;
    padding: 12px 25px;
    font-size: 0.9em;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .back-home-btn:hover {
    background-color: #0056b3;
  }
  
  /* Media Queries for Smaller Screens */
  @media (max-width: 768px) {
    .vector-image img {
      max-width: 90vw; /* Ensure the image fits smaller screens */
    }
  
    .not-found-container h2 {
      font-size: 6vw; /* Adjust header size on smaller screens */
    }
  
    .not-found-container p {
      font-size: 4vw; /* Adjust paragraph size */
    }
  
    .back-home-btn {
      font-size: 1em; /* Adjust button size on smaller screens */
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .not-found-container {
      padding: 3vw; /* Less padding for very small screens */
    }
  
    .vector-image img {
      max-width: 100%; /* Ensure the image doesn't overflow */
    }
  
    .not-found-container h2 {
      font-size: 7vw;
    }
  
    .not-found-container p {
      font-size: 4.5vw;
    }
  
    .back-home-btn {
      font-size: 0.9em; /* Slightly smaller for very small screens */
      padding: 8px 16px;
    }
  }
  
.custom-zoom-control {
    position: absolute;
    top: 20rem;
    right: 10px;
    /* height: 80px; */
    width: 30px;
    /* line-height: 1; */
    display: flex;
    flex-direction: column;
    background-color: #111418; /* Dark background color */
    border-radius: 3px; /* Rounded corners */
    overflow: hidden; /* Hide overflow */
  }
  
  .custom-zoom-control button {
    background-color: #f4f4f4; /* Dark background color */
    color: #343a40; /* Light icon text color */
    border: none; /* Remove border */
    /* line-height: 1; */
    padding: 2px;
    font-size: 24px; /* Larger font size */
    cursor: pointer; /* Pointer cursor */
    outline: none; /* Remove outline */
  }
  
  .custom-zoom-control button:hover {
    background-color: #ceced2; /* Hover color */
  }
  
  .custom-zoom-control button:first-child {
    border-bottom: 1px solid #323d494f; 
  }

  


  
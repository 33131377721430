.custom-weather-widget {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable scrolling for overflow content */
  padding: 15px;
  background-color: #212930; /* Match the dark theme background */
  color: #9fb4c7; /* Match text color */
  box-sizing: border-box;
  width: 100%;
}

.custom-header {
  text-align: center;
  margin-bottom: 0px;
}

.city-name {
  font-size: 2rem;
  margin-bottom: 10px;
}

.brand-logo {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.custom-main {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.custom-temperature {
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

.custom-details {
  flex-grow: 1; /* Allow details to grow to fill the remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.detail-item i {
  margin-right: 10px;
}

.custom-wind {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  font-size: 1.2rem;
}

.custom-wind i {
  margin-right: 10px;
}


.go-back-button-live{
  padding: 15px;
}









@media (max-width: 768px) {
  .custom-temperature {
    font-size: 3rem;
  }

  .detail-item {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .custom-temperature {
    font-size: 2rem;
  }

  .detail-item {
    font-size: 0.9rem;
  }
}
.city-name{
  font-size: 3rem;
}

@media (max-width: 340px) {
  .city-name {
    font-size: 25px;
  }

  .brand-logo{
    font-size: 18px;
  }


}

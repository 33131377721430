.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.dropdown-legend-item:hover {
  background-color: #323d49 !important; /* Change to your desired hover color */
  cursor: pointer;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.leaflet-control-edit {
  z-index: 500; /* Example value, adjust if necessary */
}

.DatePicker {
  cursor: pointer;
}
/* Custom CSS to set a fixed height for the DatePicker's dropdown */
.react-datepicker__month {
  min-height: 270px; /* Adjust this value based on your design */
}

.react-datepicker__month-container {
  max-height: 240px; /* This ensures the container doesn't expand beyond this height */
  overflow-y: auto;
}

.react-datepicker__month .react-datepicker__week {
  display: flex;
}

.react-datepicker__day {
  flex: 1;
  height: 36px; /* Adjust day height if necessary */
  line-height: 36px;
}
/* Custom CSS to change the color of the calendar navigation arrows */
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  border-color: #333; /* Dark color for the arrow border */
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
  border-color: #000; /* Even darker color on hover, optional */
}

.react-datepicker__navigation-icon::before {
  border-color: #333; /* Dark color for the arrow icon */
}

.polygon-fetch-div {
  cursor: pointer;
  background-color: azure;
  color: black;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}

/* .leaflet-draw-toolbar a {
  background-color: #212930 !important; 
  color: #9fb4c7 !important; 
  border: none !important; 

  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-draw-toolbar a:hover {
  background-color: #323d49 !important; 
}

.leaflet-draw-toolbar a i {
  font-size: 20px !important;
} */
.date-slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  margin-left: 130px !important;
  /* top: 35rem; */
  bottom: 10px;
  width: 75%;
  margin: 0 auto 20px;
  margin-top: 10vh;
  height: 60px;
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

@media (max-width: 768px) {
  .date-slider-wrapper {
    margin-left: 90px !important;
  }
}
@media (max-width: 600px) {
  .date-slider-wrapper {
    margin-left: 45px !important;
  }
}
@media (max-width: 380px) {
  .date-slider-wrapper {
    margin-left: 40px !important;
  }
}
@media (max-width: 340px) {
  .date-slider-wrapper {
    margin-left: 30px !important;
  }
}

.date-slider {
  flex-grow: 1;
  overflow-x: hidden; /* Prevents overflow but allows scrolling */
  white-space: nowrap; /* Prevents items from wrapping */
}

.empty-slider-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 18px;
  color: #fff;
  background-color: #2c2c2c;
  border-radius: 5px;
  text-align: center;
}

.dates-container {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto; /* Allows horizontal scrolling */
  scroll-behavior: smooth;
  scrollbar-width: none; /* For Firefox */
}

.dates-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.date-item {
  display: inline-flex; /* Changed from flex to inline-flex */
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #2c2c2c;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
  white-space: nowrap;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.date-item.active {
  background-color: #004ba1;
}

.date-item:hover {
  background-color: #3a3a3a;
}

.date-text {
  font-size: 14px;
}

.date-subtext {
  font-size: 12px;
  color: #8cb5a2;
}

.slider-arrow {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s ease;
  position: relative;
  z-index: 1001; /* Ensures arrows are above other elements */
}

.slider-arrow:hover {
  color: #515151;
}

/* Ensure the arrows are positioned correctly */
.slider-arrow.left {
  position: absolute;
  left: -33px;
  top: 50%;
  transform: translateY(-50%);
  background: #2c2c2c;
  padding: 15px;
  border-radius: 10%;
}

.slider-arrow.right {
  position: absolute;
  right: -34px;
  top: 50%;
  transform: translateY(-50%);
  background: #2c2c2c;
  padding: 15px;
  border-radius: 10%;
}

.map-type {
  /* right: 120px !important; */
  align-items: center;
  justify-content: center !important;
  gap: 5px;
  display: flex;

  /* top: 600px !important; */
}
.map-type-2 {
  align-items: center;
  justify-content: center !important;
  gap: 60px;
  display: flex;
  /* top: 37rem; */
}

@media (max-width: 350px) {
  .map-type-2 {
    margin-right: -30px !important;
  }
}
@media (max-width: 340px) {
  .map-type-2 {
    /* margin-right: -10px !important; */
    gap: 52px;
  }
}

@media (max-width: 1024px) {
  .map-type {
    margin-left: 30px !important;
  }
}
@media (max-width: 380px) {
  .map-type {
    margin-left: -26px !important;
    gap: 1px;
  }
}

.red-dot {
  width: 10px;
  height: 10px;
  background-color: white; /* Bright blue color */
  /* border: 1px solid white; */
  border-radius: 50%;
  position: absolute;
  top: -3px; /* Position above the button */
  right: -1px; /* Adjust the position */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.9),
    0 0 30px rgba(255, 255, 255, 0.7); /* Strong glowing effect */
  animation: blink 1s infinite; /* Blinking effect */
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.black-dot {
  width: 11px;
  height: 11px;
  background-color: rgb(66, 244, 66); /* Bright blue color */
  /* border: 1px solid white; */
  border-radius: 50%;
  position: absolute;
  top: 0; /* Position above the button */
  left: 0; /* Adjust the position */
  /* box-shadow: 0 0 15px rgba(255, 255, 255, 0.9),
    0 0 30px rgba(255, 255, 255, 0.7); Strong glowing effect */
  animation: bounce 1s infinite ease-in-out; /* Bouncing effect */
}

@keyframes bounce {
  0%,
  50% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-15px);
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

/* Target the scrollbar in a specific container */
.custom-scrollbar {
  width: 100%;
  /* padding: 10px; */
  /* border-radius: 8px; */
  background-color: #323d49; /* Background of the container */
  color: white;
  /* height: 150px; */
  /* display: flex; */
  /* align-items: center; */
  justify-content: space-between;
  position: relative;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* Custom scrollbar styles for webkit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #2a2f36; /* Background of the scrollbar track */
  border-radius: 8px; /* Rounded corners for the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #5a6573; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
  border: 2px solid #2a2f36; /* Adds some spacing between thumb and track */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #8a94a6; /* Thumb color on hover for better visibility */
}

/* Firefox scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin; /* Firefox: Makes the scrollbar thinner */
  scrollbar-color: #5a6573 #2a2f36; /* Thumb and track colors for Firefox */
}

/* Scrollbar corner style (for scrollable areas with both horizontal and vertical scrollbars) */
.custom-scrollbar::-webkit-scrollbar-corner {
  background: #2a2f36; /* Corner color */
}

/* 
@media (max-width: 768px) {
  .map-type {
    margin-left: -38px !important;
  }
}
@media (max-width: 600px) {
  .map-type {
    margin-left: -83px !important;
  }
}
@media (max-width: 380px) {
  .map-type {
    margin-left: -105px !important;
  }
  .map-type-2 {
    margin-top: -8px !important;
  }
}
@media (max-width: 365px) {
 
  .map-type-2 {
    margin-top: 6px !important;
  }
}


@media (max-width: 340px) {
  .map-type {
    margin-left: -90px !important;
  }
}
@media (max-width: 320px) {
  .map-type {
    margin-left: -98px !important;
  }
} */

.swal2-popup {
  background-color: #333;
  color: #fff;
}

.swal2-title {
  color: #fff;
}

.swal2-content {
  color: #ddd;
}

.swal2-actions .swal2-confirm {
  background-color: #28a745;
  border-color: #28a745;
}

.swal2-actions .swal2-cancel {
  background-color: #dc3545;
  border-color: #dc3545;
}

.swal2-actions .swal2-confirm:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.swal2-actions .swal2-cancel:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #343a40; /* Dark hover background color */
  color: white; /* Keep the text color white */
}

.weather-button-parent {
  position: relative;
  display: flex;
  top: "20rem";
}

.weather-button-parent-md {
  display: none;
}

.add-field-parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

/* Additional styling for RTL */
.rtl .draw-icon {
  margin-left: 10px;
  margin-right: 0;
}

.ltr .draw-icon {
  margin-right: 10px;
  margin-left: 0;
}

/* Flip the icon for RTL if needed */
.rtl-icon {
  transform: scaleX(-1);
}

.swal2-container {
  z-index: 1500 !important; /* Make sure this is higher than the Drawer */
}

/* @media (max-width: 380px) {
  .weather-button-parent-lg {
    display: none;
  }

  .weather-button-parent-md {
    display: block;
  }
} */

@media (max-width: 365px) {
  .date-slider-wrapper {
    /* top: 31rem; */
  }
  /* .map-type {
    top: 32.5rem;

  } */
}
@media (max-width: 322px) {
  .weather-button-parent-md {
    right: 20% !important;
  }
}

.drawer {
  background-color: #212930;
  color: #fff;
  transition: height 0.5s ease;
  overflow: hidden;
  /* position: fixed; */
  position: relative;
  bottom: 65px; /* Position above the button */
  left: 50%;
  border-radius: 8px;
  transform: translateX(-50%);
  width: 90%;
  max-width: 250px;
}


/* DrawerTest.css */
.drawer-test-container {
  display: flex;
}

.drawer {
  width: 300px; /* Adjust the width as necessary */
  border-right: 1px solid #ccc;
}

.polygon-drawer {
  flex-grow: 1;
  height: 100vh; /* Ensure it takes the full height */
}


.drawer-lg {
  height: 100vh;
  width: 250px;
  bottom: auto;
  border-radius: 0;
  top: 0;
  left: 0;
  transform: none;
}

.drawer-closed {
  height: 0;
}

.drawer-open {
  height: 60vh; /* Adjust as needed for the open state */
}

.drawer-toggle-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #323d49;
  color: #fff;
  cursor: pointer;
  border-radius: 8px 8px 0 0;

  position: fixed;
  bottom: 0px;
  width: 90%;
  max-width: 250px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000; /* Ensure button is above the drawer */
}

.drawer-toggle-text {
  flex-grow: 1;
  text-align: center;
}

.drawer-toggle-icon {
  margin-left: auto;
}

.heading-parent {
  display: inline-flex;
    align-items: center;
width: 100%;
  border-bottom: 1px solid #111418;
}

.drawer-heading{
  padding: 16px;
  text-align: left;
  white-space: nowrap;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.info-icon-drawer{
  margin-right: 10px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 30px;
    color: #8296aa;
   
    

}

.drawer-open .drawer-content,
.drawer-lg .drawer-content {
  display: block;
}

.hidden {
  display: none;
}

.add-field-parent {
  padding: 6px;
  max-width: 100%;
  margin-top: 50%;
}

.add-field-child {
  min-height: 108px;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: rgba(53, 146, 253, 0.2);
  border: 1px dashed #3592fd;
  border-radius: 8px;
}

.draw-icon {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  color: #3592fd;
  margin-top: 15px;
}

.draw-text-parent {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.first-text-draw {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  display: flex;  
  line-height: 20px;
  color: #3592fd;
}

.second-text-draw {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #9fb4c7;
  text-align: justify;
}

@media (max-width: 1100px) {
  .add-field-parent {
    /* margin-top: 30%; */
    /* position: fixed; */
  }
}

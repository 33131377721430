.weather-container {
  font-family: "Noto Nastaliq Urdu", serif;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background-color: #f7f9fc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.weather-container h1 {
  text-align: center;
  color: #333;
}

.weather-date-section {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.weather-date-section h2 {
  margin-bottom: 10px;
  color: #0066cc;
}

.weather-date-section table {
  width: 100%;
  border-collapse: collapse;
}

.weather-date-section th, .weather-date-section td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.weather-date-section th {
  background-color: #f2f2f2;
  color: #333;
}

.weather-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.weather-container th, .weather-container td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.weather-container th {
  background-color: #f2f2f2;
  color: #333;
}

/* styles.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu&display=swap');

body {
  font-family: Arial, sans-serif; /* Default font for the body */
}

.urdu-font {
  font-family: 'Noto Nastaliq Urdu', Arial, Helvetica, sans-serif;
}

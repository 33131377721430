/* YourComponent.css */

.swal-dark {
  background-color: #333 !important; /* Dark background */
  color: #fff !important; /* White text */
}

.swal-dark-title {
  color: #fff !important; /* White title text */
  font-size: 20px;
}

.swal-dark-icon {
  /* color: #fff !important; Adjust icon color if needed */
}

/* Optionally, you can style buttons or other elements */
.swal2-styled.swal2-confirm {
  background-color: rgb(0, 75, 161) !important; /* Dark button */
  border: none;
}

.swal2-styled.swal2-cancel {
  background-color: #444 !important; /* Darker button */
  border: none;
}

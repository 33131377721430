











@media (max-width: 380px) {
    .company-logo-about{
     display: grid !important;
    }
    .uaf-logo-about{
        margin-left: 14px;
    }
    .gzis-logo-about{
        margin-left: 10px;
    }
  }
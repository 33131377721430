/* src/pages/Leaflet-Map-Components/SearchComponent.css */
/* SearchComponent.css */
.marker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .marker-wrapper i.fa-circle {
    animation: pulse 1s infinite;
  }
  
  .marker-wrapper:hover i.fa-circle {
    animation: none; /* Stop animation on hover */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.6;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }




  .leaflet-control-geocoder.leaflet-bar-part {
    height: 40px !important; /* Increase the height as needed */
    display: flex;
    padding: 30px;
    align-items: center;
  }
  
  .leaflet-control-geocoder-form input {
    height: 100%;
    /* max-width: 150px; */
    padding-right: 40px;
    /* background-color: #f4f4f4 !important; Set background color */
    border: none; /* Optional: Remove border for cleaner look */
    box-shadow: none; /* Optional: Remove box shadow */
  }
  
  .custom-geocoder-wrapper {
    position: relative;
    width: 100%;
  }
  
  /* .custom-geocoder-logo {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
  } */

  .custom-geocoder-logo-text {
    font-size: 14px;
    font-weight: bold;
    background-color: #004ba1;
    color: white; /* Customize the color as needed */
    /* border-radius: 5px; */
    padding: 7px;
    /* margin-left: 8px;
    vertical-align: middle; /* Align the text vertically with the input */
}




@media (max-width: 622px) {
  .leaflet-control-geocoder-form input{
    max-width: 150px !important;
  }
}
@media (max-width: 426px) {
  .leaflet-control-geocoder-form input{
    max-width: 130px !important;
    font-size: 16px;
  }
}
@media (max-width: 386px) {
  .leaflet-control-geocoder-form input{
    max-width: 100px !important;
  }
}
  
@media (max-width: 326px) {
  .leaflet-control-geocoder-form input{
    max-width: 80px !important;
    font-size: 12px;
  }
}

 